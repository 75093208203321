<template>
  <div style="background: #F2F3F8; width: 100%;height: 100%;padding-top: 20px;">
    <div class="personalCenterContainer">
      <div class="titles">
        <!-- <i
          class="el-icon-arrow-left"
          style="font-size: 18px;cursor:pointer;"
          @click="goBack"
        ></i> -->
        <!-- 身份认证 -->
        <div class="leftFlex">
          <img src="../../../assets/images/header/tx.png" alt="" srcset="" />
        <span class="userName">{{ firmName }}</span>
        <span class="rzzt" :class="approveState == '已认证' ? 'yrz' : '' " >{{ approveState }}</span>
         <div v-if="returnNote" class="returnsNote" style="padding-left: 20px">
              驳回理由：{{ returnNote }}
        </div>
        </div>
        <div class="alreadyAuth" v-if="createCodeShow && lst_flag">
              <!-- <div>生成邀请码</div> -->
              <div class="inviteCode">
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-input
                      v-model="cellphone"
                      placeholder="请输入手机号"
                    ></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      type="primary"
                      :disabled="btnDisabled"
                      @click="createInviteCode()"
                      >生成邀请码</el-button
                    >
                  </el-col>
                </el-row>
              </div>
              <!-- <el-input class="staffAccountInput" v-model="cellphone" placeholder="请输入手机号码"></el-input> -->
              <div class="inviteCodeTime rightFont">邀请码将发送到员工手机号中</div>
        </div>
      </div>
      <!-- <div style="background: #F2F3F8;height: 20px;width:100%"></div> -->
      <div>
        <div class="userMessage">
          <div class="returnType">
            <!-- <el-button
              size="mini"
              id="arrowLeft"
              icon="el-icon-arrow-left"
              @click="arrowLeftClick"
            ></el-button> -->
            <!-- <div v-if="returnTypeFlag">
              <i
                class="el-icon-arrow-left"
                style="cursor:pointer"
                @click="arrowLeftClick"
              ></i>
              <span
                style="cursor:pointer"
                class="typeText"
                @click="arrowLeftClick"
                >返回</span
              >
            </div>
          </div>
          <div class="titless">
            <div>
              <div class="userMesItem">
                <span>登录账号: {{ userMes.tel }}</span>
                <div v-if="!isBaiDuRZ">认证状态: {{ approveState }}</div>
              </div>
            </div> -->
            <!-- <el-button icon="el-icon-search" circle></el-button> -->
            <!-- <div class="userMesItem">
              <span>登录账号: {{ userMes.tel }}</span>
              <div v-if="!isBaiDuRZ">认证状态: {{ approveState }}</div>
            </div> -->
            <!-- 加入企业 -->
            <div
              class="alreadyAuth"
              v-if="firmListGetter.length!=0&&firmListGetter[0].marketId==18&&isUserShow && !recordFlag && lst_flag"
            >
              <p>我的公司已被认证？</p>
              <p>请输入邀请码加入成为员工账号</p>
              <div class="inviteCode">
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-input
                      v-model="inviteCode"
                      placeholder="请输入邀请码"
                    ></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      type="primary"
                      :disabled="btnDisabled"
                      @click="authEnterprise()"
                      >验证并加入</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- 生成邀请码 -->
            <!-- <div class="alreadyAuth" v-if="createCodeShow && lst_flag">
              <div>生成邀请码</div>
              <div class="inviteCode">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-input
                      v-model="cellphone"
                      placeholder="请输入手机号"
                    ></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      type="primary"
                      :disabled="btnDisabled"
                      @click="createInviteCode()"
                      >生成邀请码</el-button
                    >
                  </el-col>
                </el-row>
              </div>
              <div class="inviteCodeTime">邀请码将发送到员工手机号中</div>
            </div> -->
          </div>
        </div>

        <div class="enterpriseAuthContainer">
          <el-row>
            <!-- <div v-if="returnNote" class="returnsNote">
              驳回理由：{{ returnNote }}
            </div> -->
            <!-- <el-col :span="24">
            <div class="notAuth">
              <el-button v-if="approveStateFlag == 1 || approveStateFlag == 0" class="enterpriseAuthButton" type="danger" @click="showEnterpriseAuthDialog()">公司认证</el-button>
              <el-button v-if="approveStateFlag == 2 || approveStateFlag == 0" class="enterpriseAuthButton" type="danger" @click="showPersonalCertificate()">个人认证</el-button>
            </div>
          </el-col> -->
          </el-row>
          <!-- <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            v-if="!isBaiDuRZ"
            v-loading="loading"
          > -->
            <!-- v-if="approveStateFlag == 1 || approveStateFlag == 0" -->
            <!-- <el-tab-pane
              label="个人认证"
              v-if="auditType.auditType == 'P'"
              name="first"
            >
              <personalCertificate
                :datas="datas"
                :BDAuthentication="BDAuthentication"
                :isStep="false"
                ref="personalCertificate"
                @reLoad="reLoad()"
              />
            </el-tab-pane> -->
            <!-- v-if="approveStateFlag == 2 || approveStateFlag == 0" -->
            <!-- <el-tab-pane
              label="公司认证"
              v-if="auditType.auditType == 'O'"
              name="second"
            > -->
              <!-- 企业认证 -->
              <enterpriseAuthDialog
                :datas="datas"
                :isTwo="isTwo"
                :isStep="false"
                :BDAuthentication="BDAuthentication"
                ref="enterpriseAuthDCialog"
                @reLoad="reLoad()"
              />
            <!-- </el-tab-pane>
          </el-tabs> -->
        </div>
        <div v-if="isBaiDuRZ" class="qrC">
          <div class="qrcText">请先进行人脸识别</div>
          <div id="qrCode" ref="qrCodeDiv"></div>
          <div
            style="margin-top: 15px;font-weight: 700;color: red;font-size: 18px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import enterpriseAuthDialog from "../com/enterpriseAuthDialog";
import personalCertificate from "../com/personalCertificate";
import QRCode from "qrcodejs2-fix";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
     firmName: sessionStorage.getItem("userName"),
      returnTypeFlag: false,
      loading: true,
      auditType: {
        auditType: "A"
      },
      createCodeShow: false,
      cellphone: "",
      lst_flag: window.g.LST_FLAG,
      btnDisabled: false,
      isFlag3: false,
      recordFlag: false,
      isFlagss: false,
      isUserShow: false,
      isShows: false,
      isTwo: false,
      dataO: {},
      dataP: {},
      BDAuthentication: {
        idCardNumber: "",
        name: "",
        userId: null
      },
      isBaiDuRZ: false,
      timer: null,
      activeName: "first",
      inviteCode: null,
      isNotPass: false,
      audit: {
        memberNote: null,
        riskNote: null
      },
      returnNote: "", // 驳回理由
      approveState: "查询中...", // 认证状态
      approveStateFlag: 0, // 按钮展示状态
      datas: {},
      userMes: {},
      userImage: require("../../../assets/images/244.png")
    };
  },
  components: {
    enterpriseAuthDialog,
    personalCertificate
  },
  computed: {
    ...mapGetters(["firmListGetter"])
  },
  created() {
    const that = this;
    setTimeout(() => {
      that.goPath();
    }, 500);
    // this.param_getIdentificationInfo();
  },
  mounted() {
    // this.getIdentificationStatus();
    var that = this;
    this.userMes = this.$store.state.common.sessionInfo;
    // this.param_getVerifyToken();
    this.searchRecord();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    arrowLeftClick() {
      this.$router.push("/certificationTypes");
    },
    goPath() {
      const that = this;
      // 查询认证类型
      http.postFront(protocol.param_queryCertificationType).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value && value.auditType) {
            this.loading = false;
            if (value.auditType === "P") {
              // 个人 查询百度认证
              this.activeName = "first";
              this.param_getIdentificationInfo();
              // 获取二维码
              this.param_getVerifyToken();
              this.timer = setInterval(() => {
                that.param_getIdentificationInfo();
              }, 2000);
            } else if (value.auditType === "O") {
              // 企业
              this.getIdentificationStatus();
              this.auditType.auditType = "O";
              this.activeName = "second";
            }
          } else {
            // 未选择认证类型
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 生成邀请码
    createInviteCode() {
      if (this.cellphone && this.cellphone.match(/^1\d{10}$/)) {
        protocol.param_generateCode.param.cellphone = this.cellphone;
        protocol.param_generateCode.param.companyId = 8;
        http.postFront(protocol.param_generateCode).then(response => {
          const { code, message } = response.data;
          if (Number(code) === 0) {
            this.cellphone = null;
            this.$toast(message);
          } else {
            this.$toast(message);
          }
        });
      } else {
        this.$toast("请输入正确格式的手机号码");
      }
    },
    goBack() {
      this.$router.push("/userOrSafety");
    },
    // 查询个人企业绑定记录
    searchRecord() {
      http.postFront(protocol.param_getBaseUserBindByUserId).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          console.log("企业绑定记录", value);
          if (value) {
            this.recordFlag = true;
          } else {
            this.recordFlag = false;
          }
        }
      });
    },
    authEnterprise() {
      this.btnDisabled = true;
      if (this.inviteCode) {
        protocolFwd.param_bindCertification.param.code = this.inviteCode;
        http
          .postFrontLST(protocolFwd.param_bindCertification)
          .then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.searchRecord();
              this.btnDisabled = false;
              this.inviteCode = null;
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
              this.getIdentificationStatusa();
              // window.setTimeout(() => {
              //   this.$router.push('/personalCenter/enterprise');
              // }, 1000);
            } else {
              this.inviteCode = null;
              this.$EL_MESSAGE(message);
              this.btnDisabled = false;
            }
          });
      } else {
        this.btnDisabled = false;
        this.$EL_MESSAGE("请输入邀请码");
      }
    },
    // 认证状态查询
    getIdentificationStatus() {
      this.isFlag3 = false;
      console.log(this.isBaiDuRZ, "isBaiDuRZ");
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            // value.personCertType = 1;
            // value.orgCertType = 1;
            this.datas = value;

            // this.param_getIdentificationInfo()
            console.log(787878778);
            if (value == null) {
              this.approveState = "未认证";
              this.approveStateFlag = 0;
              // 未认证,展示返回按钮
              // 判断是个人还是企业
              if (this.auditType.auditType == "O") {
                // 企业
                this.returnTypeFlag = true;
              } else {
                // 个人
                this.returnTypeFlag = false;
              }
            } else {
              // 已存在认证信息,不展示返回按钮
              this.returnTypeFlag = false;
              // 认证类型（O:组织认证 P:个人认证）
              if (value.auditType == "O") {
                // this.flag1 = false
                this.approveStateFlag = 2;
                this.activeName = "second";
                if (value.auditStatus == 1) {
                  this.createCodeShow = true;
                }
              } else {
                this.approveStateFlag = 1;
                this.activeName = "first";
                if (value.auditStatus == 0) {
                  this.approveState = "未审核";
                } else if (value.auditStatus == 1) {
                  this.approveState = "已认证";
                  this.isUserShow = true;
                } else if (value.auditStatus == 2) {
                  this.approveState = "已驳回";
                  this.returnNote = value.note;
                }
                // this.enterpriseCertificationMes()
              }
              // 审核状态（0:未审核 1:已认证 2:已驳回）
              if (value.auditStatus == 0) {
                this.approveState = "未审核";
              } else if (value.auditStatus == 1) {
                this.approveState = "已认证";
                this.isFlag3 = true;
              } else if (value.auditStatus == 2) {
                this.approveState = "已驳回";
                this.returnNote = value.note;
              }
            }
            this.datas.personCertType = 1;
            this.datas.orgCertType = 1;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // 获取百度认证信息
    param_getIdentificationInfo() {
      this.isFlagss = false;
      const _this = this;
      http.postFront(protocol.param_getIdentificationInfo).then(response => {
        const { code, value } = response.data;
        if (code == 0) {
          if (value) {
            console.log("查询成功");
            this.isBaiDuRZ = false;
            this.isFlagss = false;
            this.BDAuthentication = value;
            this.getIdentificationStatus();
            clearInterval(_this.timer);
            _this.timer = null;
            // 通过百度认证,不展示返回按钮
            this.returnTypeFlag = false;
          } else {
            // 未通过百度认证,展示返回按钮
            this.returnTypeFlag = true;
            console.log("查询失败");
            this.isBaiDuRZ = true;
            this.isFlagss = true;
            // this.$EL_MESSAGE(message)
          }
        } else {
        }
      });
    },
    // 获取token跳转百度二维码
    param_getVerifyToken() {
      if (this.isBaiDuRZ && this.isBaiDuRZ != 1000) {
        return;
      }
      const _this = this;
      http.postFront(protocol.param_getVerifyToken).then(response => {
        const { code, message, value } = response.data;
        if (code == 0 && value) {
          this.auditType.auditType = "P";
          _this.token = value.result.verifyToken;
          _this.$nextTick(function() {
            _this.bindQRCode();
          });
        } else {
          _this.$EL_MESSAGE(message);
        }
      });
    },
    // 二维码加载
    bindQRCode: function() {
      if (this.$refs.qrCodeDiv && this.$refs.qrCodeDiv.innerHTML) {
        this.$refs.qrCodeDiv.innerHTML = "";
      }
      if (this.$refs.qrCodeDiv1 && this.$refs.qrCodeDiv1.innerHTML) {
        this.$refs.qrCodeDiv1.innerHTML = "";
      }
      const token = this.token;
      const successUrl = window.g.BAIDU_SUCCESS_URL;
      const failedUrl = window.g.BAIDU_FAILED_URL;
      this.bdurl = `https://brain.baidu.com/face/print/?token=${token}&successUrl=${successUrl}${this.userMes.userId}&failedUrl=${failedUrl}`;
      new QRCode(this.$refs.qrCodeDiv, {
        text: `https://brain.baidu.com/face/print/?token=${token}&successUrl=${successUrl}${this.userMes.userId}&failedUrl=${failedUrl}`,
        width: 200,
        height: 200,
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
      });
      new QRCode(this.$refs.qrCodeDiv1, {
        text: `https://brain.baidu.com/face/print/?token=${token}&successUrl=${successUrl}${this.userMes.userId}&failedUrl=${failedUrl}`,
        width: 200,
        height: 200,
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
      });
      this.isShows = true;
    },
    handleClick() {
      // this.getIdentificationStatus()
    },
    // 企业信息认证
    showEnterpriseAuthDialog() {
      this.$refs.enterpriseAuthDCialog.showDialogs();
    },
    // 个人信息认证
    showPersonalCertificate() {
      this.$refs.personalCertificate.showDialog();
    },
    // 企业认证查询(二次)
    enterpriseCertificationMes() {
      http.postFront(protocol.param_enterpriseCertification).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          if (!value) {
            // 未查询到认证信息
            this.flag1 = true;
            // this.approveStateFlag = 2
            this.activeName = "first";
            this.isTwo = true;
          } else {
            this.dataO = value;
          }
        }
      });
    },

    reLoad() {
      this.getIdentificationStatus();
      this.$emit("reLoad");
      this.searchRecord();
    }
  }
};
</script>
<style lang="scss" scoped>
.returnType {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .typeText {
    font-size: 14px;
    margin-left: 10px;
  }
}
/deep/ .is-active {
  color: #0193de !important;
}
/deep/ .el-tabs__active-bar {
  background-color: #0193de;
}
.personalCenterContainer {
  // background-color: #fff;
  width: 1200px;
  margin: 0 auto;

  line-height: 32px;
  height: 100%;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.enterpriseAuthContainer {
  // padding: 35px 0;
  // border: 1px solid #ccc;
}
.enterpriseAuthContainer .alreadyAuth {
  padding: 20px 0;
  line-height: 35px;
  border-right: 1px solid #f2f2f2;
}
.enterpriseAuthContainer .alreadyAuth .inviteCode {
  margin: 10px 0;
}
.enterpriseAuthContainer .notAuth {
  padding: 20px 0;
}
.enterpriseAuthContainer .notAuth .enterpriseAuthButton {
  margin: 10px 0;
}
.auditResult {
  margin-top: 15px;
}
.qrC {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  .qrcText {
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
  }
}
.inviteCode {
  margin: 10px 0;
}
.leftFlex{
  display: flex;
  align-items: center;
}
.titless {
  display: flex;
  justify-content: space-between;
}
.titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-size: 18px;
  background-color: #fff;
  border-radius: 8px;
  img {
    width: 60px;
  }
  .userName {
    font-weight: bold;
    font-size: 24px;
    color: #333333;
    margin-left: 20px;
    margin-right: 12px;
  }
  .rzzt {
    background: #f2f3f8;
    border-radius: 13px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 16px;
    color: #999999;
    padding: 6px 20px;
  }
  .yrz{
    color: #2F61FB;
    background: #DFE4FF;
  }
}
.enterpriseAuthContainer{
  background-color: #ffffff;
    border-radius: 8px;
    margin-top: 30px;
}
/deep/ .el-form-item {
    display: flex;
    /* margin-bottom: 22px; */
    flex-direction: column;
}
/deep/ .el-form-item__label {
    text-align: left;
}
/deep/ .el-form-item__content {
    margin-left: 0 !important;
}
/deep/ .el-input__inner {
    border-radius: 8px;
    border: 1px solid #DCDCDC;
}
/deep/ .el-input.is-disabled .el-input__inner{
  // background-color: #F3F3F3;
}
.alreadyAuth{
  display: flex;
  align-items: center;
}
/deep/ .el-button--primary{
    background: linear-gradient(0deg, #4C87F0 1%, #2D69D3 100%);
    border-radius: 8px;
  }
  .rightFont{
    font-weight: 300;
  font-size: 16px;
  color: #999999;
  }
</style>
